// frontend/src/Notifications.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Notifications() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get('http://localhost:5000/api/notifications', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setNotifications(response.data);
  };

  const markAsRead = async (id) => {
    const token = localStorage.getItem('token');
    const response = await axios.put(`http://localhost:5000/api/notifications/${id}`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setNotifications(notifications.map(notification => (notification._id === id ? response.data : notification)));
  };

  return (
    <div>
      <h1>Notifications</h1>
      <ul>
        {notifications.map(notification => (
          <li key={notification._id}>
            {notification.message}
            {!notification.read && <button onClick={() => markAsRead(notification._id)}>Mark as Read</button>}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications;