// frontend/src/Contact.js
import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Email: support@digileap.com</p>
    </div>
  );
}

export default Contact;