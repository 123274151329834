import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Products() {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ name: '', description: '', price: 0, stock: 0 });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get('http://localhost:5000/api/products', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProducts(response.data);
  };

  const addProduct = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.post('http://localhost:5000/api/products', newProduct, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProducts([...products, response.data]);
    setNewProduct({ name: '', description: '', price: 0, stock: 0 });
  };

  return (
    <div>
      <h1>Products</h1>
      <ul>
        {products.map(product => (
          <li key={product._id}>
            {product.name}: {product.description} - ${product.price} (Stock: {product.stock})
          </li>
        ))}
      </ul>
      <input
        type="text"
        value={newProduct.name}
        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        placeholder="Name"
      />
      <input
        type="text"
        value={newProduct.description}
        onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        placeholder="Description"
      />
      <input
        type="number"
        value={newProduct.price}
        onChange={(e) => setNewProduct({ ...newProduct, price: Number(e.target.value) })}
        placeholder="Price"
      />
      <input
        type="number"
        value={newProduct.stock}
        onChange={(e) => setNewProduct({ ...newProduct, stock: Number(e.target.value) })}
        placeholder="Stock"
      />
      <button onClick={addProduct}>Add Product</button>
    </div>
  );
}

export default Products;