// frontend/src/BusinessData.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BusinessData() {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState({ name: '', value: 0 });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get('http://localhost:5000/api/business-data', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData(response.data);
  };

  const addData = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.post('http://localhost:5000/api/business-data', newData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData([...data, response.data]);
    setNewData({ name: '', value: 0 });
  };

  const updateData = async (id, updatedData) => {
    const token = localStorage.getItem('token');
    const response = await axios.put(`http://localhost:5000/api/business-data/${id}`, updatedData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData(data.map(item => (item._id === id ? response.data : item)));
  };

  const deleteData = async (id) => {
    const token = localStorage.getItem('token');
    await axios.delete(`http://localhost:5000/api/business-data/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setData(data.filter(item => item._id !== id));
  };

  return (
    <div>
      <h1>Business Data</h1>
      <ul>
        {data.map(item => (
          <li key={item._id}>
            {item.name}: {item.value}
            <button onClick={() => updateData(item._id, { name: item.name, value: prompt('New value:', item.value) })}>Update</button>
            <button onClick={() => deleteData(item._id)}>Delete</button>
          </li>
        ))}
      </ul>
      <input
        type="text"
        value={newData.name}
        onChange={(e) => setNewData({ ...newData, name: e.target.value })}
        placeholder="Name"
      />
      <input
        type="number"
        value={newData.value}
        onChange={(e) => setNewData({ ...newData, value: Number(e.target.value) })}
        placeholder="Value"
      />
      <button onClick={addData}>Add Data</button>
    </div>
  );
}

export default BusinessData;