import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './context/AuthContext';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [businessData, setBusinessData] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:5000/api/business-data', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBusinessData(response.data);
    };

    const fetchNotifications = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get('http://localhost:5000/api/notifications', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotifications(response.data);
    };

    fetchBusinessData();
    fetchNotifications();
  }, []);

  return (
    <div className="dashboard">
      <h1>Welcome, {user?.username}</h1>
      <div className="dashboard-sections">
        <div className="section">
          <h2>Profile</h2>
          <Link to="/profile">Go to Profile</Link>
        </div>
        <div className="section">
          <h2>Business Data</h2>
          <Link to="/business-data">View Business Data</Link>
          <ul>
            {businessData.map((data) => (
              <li key={data._id}>{data.name}: {data.value}</li>
            ))}
          </ul>
        </div>
        <div className="section">
          <h2>Notifications</h2>
          <Link to="/notifications">View Notifications</Link>
          <ul>
            {notifications.map((notification) => (
              <li key={notification._id}>{notification.message}</li>
            ))}
          </ul>
        </div>
        <div className="section">
          <h2>Settings</h2>
          <Link to="/settings">Go to Settings</Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;