// src/About.js
import React from 'react';
import Home from './Home';
import './Home.css'; // Import the CSS file for styling

function About() {
  return (
    <Home>
      <div className="content-container">
        <h1>About Us</h1>
        <p>Digileap is dedicated to providing the best tools for managing your business.</p>
      </div>
    </Home>
  );
}

export default About;