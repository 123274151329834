import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import App from './App';
import Login from './components/Login';
import Profile from './Profile';
import Dashboard from './Dashboard';
import BusinessData from './BusinessData';
import Notifications from './Notifications';
import Settings from './Settings';
import Home from './Home';
import About from './About';
import Features from './Features';
import Contact from './Contact';
import Register from './components/Register';
import Products from './Products';
import Payment from './Payment';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={
            <Home>
              <div className="hero-section">
                <h1>DigiLeap</h1>
                <p>The best platform to digitalize your brand.</p>
                <div className="scroll-down">
                  <span>&#8595;</span>
                </div>
              </div>
            </Home>
          } />
          <Route path="/about" element={<Home><About /></Home>} />
          <Route path="/features" element={<Home><Features /></Home>} />
          <Route path="/contact" element={<Home><Contact /></Home>} />
          <Route path="/register" element={<Home><Register /></Home>} />
          <Route path="/login" element={<Home><Login /></Home>} />
          <Route path="/profile" element={<Home><Profile /></Home>} />
          <Route path="/dashboard" element={<Home><Dashboard /></Home>} />
          <Route path="/business-data" element={<Home><BusinessData /></Home>} />
          <Route path="/notifications" element={<Home><Notifications /></Home>} />
          <Route path="/settings" element={<Home><Settings /></Home>} />
          <Route path="/products" element={<Home><Products /></Home>} />
          <Route path="/payment" element={<Home><Payment /></Home>} />
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);