// frontend/src/Features.js
import React from 'react';

function Features() {
  return (
    <div>
      <h1>Features</h1>
      <ul>
        <li>User Authentication</li>
        <li>Profile Management</li>
        <li>Dashboard with Analytics</li>
        <li>CRUD Operations for Business Data</li>
        <li>Notifications System</li>
        <li>Settings and Preferences</li>
      </ul>
    </div>
  );
}

export default Features;